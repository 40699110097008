
import './App.css';
import React from 'react';

import MenuList from './components/MenuList';

function App() {
  React.useEffect(() => {
    window.addEventListener("contextmenu", function(e) { e.preventDefault(); })
  });

  
  return (
    <div className="App">
      <MenuList />
    </div>
  );
}

export default App;
