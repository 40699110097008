import React, { useRef, useState, useEffect } from "react";

import Video from "./Video";
import VideoPlaceholder from "./VideoPlaceholder";
import Gallery from "./Gallery";

import "./MenuList.css";

import mainLogo from "../assets/image/mainLogo.svg";
import axios from 'axios';

const MenuList = (props) => {

  const [homescreenMenus, setHomescreenMenus] = useState(window.homescreenMenus)
  const [videos, setVideos] = useState(window.videos)
  const [videoBaseUrl, setVideoBaseUrl] = useState(window.video_base_url.base_url);
  const [selectedLanguage, setSelectedLanguage] = useState("hu");
  const [selectedMenu, setSelectedMenu] = useState(null);
  const [startVideo, setStartVideo] = useState(false);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isGalleryActive, setIsGalleryActive] = useState(false);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(true)

  const [selectedTranslations, setSelectedTranslations] = useState(
    homescreenMenus[selectedLanguage]
  );

  // console.log('selectedTranslations', selectedTranslations);

  const onSelectedLanguageHandler = (event) => {
    setSelectedLanguage(event.target.getAttribute("data-language"));
    setSelectedTranslations(
      homescreenMenus[event.target.getAttribute("data-language")]
    );

    if(isVideoPlaying) {
      console.log('video is playing, so change video language');
      let videoName = videos[selectedMenu][event.target.getAttribute("data-language")]
      console.log('videoName', videoName);
      playMedia(videoName);
    }    
  };

  const playVideoHandler = (event) => {
    let menuButtons = document.querySelectorAll('.menu');
    
    menuButtons.forEach(menuButton => {
      if(menuButton.CDATA_SECTION_NODE.menu != event.target.getAttribute('data-menu')) {
        menuButton.classList.add('inactive')
      }
    });
    setTimeout(() => {
      menuButtons.forEach(menuButton => {
        menuButton.classList.remove('inactive');
      })
    }, 1500);


    if (startVideo) {
      setStartVideo(false);
      setTimeout(() => {
        setIsGalleryActive(false);
        setSelectedMenu(event.target.getAttribute("data-menu"));
        setStartVideo(true);
      }, 50);
    } else {
      setIsGalleryActive(false);
      setSelectedMenu(event.target.getAttribute("data-menu"));
      setStartVideo(true);
    }


    let inactiveButtons = document.querySelectorAll('.inactive');
    console.log('inactiveButtons: ', inactiveButtons)

    setTimeout(() => {
      inactiveButtons.forEach(button => {
        button.classList.remove('inactive');
      })
    }, 2000);

    let video = videos[event.target.getAttribute("data-menu")][selectedLanguage];
    console.log('video',video)
    playMedia(videos[event.target.getAttribute("data-menu")][selectedLanguage])
  };


  const playMedia = (video) => {
    setIsVideoPlaying(true); 
    getData(`${videoBaseUrl}play?file=media/${video}`);
  }


  useEffect(() => {
    const mediaInterval = setInterval(() => {
      if(isVideoPlaying) {
        checkStatus(videoBaseUrl + 'status');
      }      
    }, 1000);
    return () => clearInterval(mediaInterval);
  }, [isVideoPlaying]);

  const checkStatus = (url) => {
    axios.get(url).then(res => {
      console.log('statusResponse', res.data)
      if(res.data == '') {
        setIsVideoPlaying(false);    
      } else {
        setIsVideoPlaying(true);  
      }
      

    }).catch(function(error) {
      // manipulate the error response here
  });
  }

  const stopMedia = () => {
    let stopUrl = videoBaseUrl + "stop";
    axios.get(stopUrl).then(res => {
      console.log('stopMediaResponse', res)
    })
  }


  function getData(url) {
    let response;
    axios.get(url).then(res => {
      console.log(res.data)
    }).catch(function(error) {
      // manipulate the error response here
  });
    // return response;
  }

  // const pullData = (data) => {
  //   console.log("data", data);
  //   if (data == "videoFinished") {
  //     setStartVideo(false);
  //   }
  // };


  const onHandlerGalleryComponent = () => {
    setIsGalleryActive(true);
    setStartVideo(false); 
    setIsVideoPlaying(false);
    console.log('gallery started');   
    stopMedia();
    
    // let  screenTouchInterval = setInterval (function () {
    //   timeElapsed += 1;
    //   console.log('timeelapsed', timeElapsed)
  
    //   if (timeElapsed == 4) {
    //     setIsGalleryActive(false)
    //     clearInterval(screenTouchInterval)
    //     setLightboxIsOpen(false)
    //     setLightboxIsOpen(false)
    //   }
    // }, 5000);
    
  };

  return (
    <div className="mainContainer">
      <div className="menuContainer">
        <div className="header">
          <div className="languageSelector">
            <a
              className={`language ${selectedLanguage == "hu" ? "active" : ""}`}
              data-language="hu"
              onClick={onSelectedLanguageHandler}
            >
              HU
            </a>
            <a
              className={`language ${selectedLanguage == "ro" ? "active" : ""}`}
              data-language="ro"
              onClick={onSelectedLanguageHandler}
            >
              RO
            </a>
            <a
              className={`language ${selectedLanguage == "en" ? "active" : ""}`}
              data-language="en"
              onClick={onSelectedLanguageHandler}
            >
              EN
            </a>
          </div>
          <div className="mainTitleContainer">
            {selectedLanguage == "hu" && (
              <div className="mainTitle2 subtitle unselectable">
                {selectedTranslations.subTitle}
              </div>
            )}
            <div className="mainTitle unselectable">{selectedTranslations.mainTitle}</div>
            {selectedLanguage != "hu" && (
              <div className="mainTitle2 subtitle unselectable">
                {selectedTranslations.subTitle}
              </div>
            )}
            <div className="mainTitle2 memories unselectable">
              {selectedTranslations.mainTitle2}
            </div>
            <div className="mainTitle2 unselectable">{selectedTranslations.mainTitle3}</div>
          </div>

          <div className="mainLogoTop">
            <img src={mainLogo} alt="" />
          </div>
          <div>
            <div className="description1 unselectable">
              {selectedTranslations.description2}
            </div>
          </div>
        </div>

        <div className="questions">
          {selectedTranslations.menuList.map((menu) => {
            return (
              <h4
                key={menu.name}
                className={`menu unselectable ${
                  selectedMenu == menu.name && isVideoPlaying ? "active" : ""
                }`}
                data-menu={menu.name}
                onClick={playVideoHandler}
              >
                {menu.label}
              </h4>
            );
          })}
        </div>

        <div className="footer">
          <div className="mainLogoBottom">
            <img src={mainLogo} alt="" />
          </div>
          <div className={`quizLabel ${isGalleryActive ? "active" : ""}`}>
            <div className="quizLabelText unselectable" onClick={onHandlerGalleryComponent}>
              {selectedTranslations.quizLabel1}
              <br></br>
              {selectedTranslations.quizLabel2}
            </div>
          </div>
        </div>
      </div>
      <div
        className={`videosContainer ${
          isGalleryActive ? "transparentComponent" : ""
        }`}
      >
        {/* {videos.map((video) => (
          <div
            key={video.name}
            className={`menuVideoComponentContainer ${
              selectedMenu === video.name ? "videoOnTop" : ""
            }`}
          >
            <Video
              id={video.name}
              videoName={video.name}
              selectedLanguage={selectedLanguage}
              startVideo={
                startVideo && selectedMenu == video.name ? true : false
              }
              func={pullData}
            ></Video>
          </div>
        ))} */}
        <div
          className={`placeholderVideoComponentContainer videoOutContainer ${
            isGalleryActive ? "transparentVideo" : "visibleVideo"
          }`}
        >
          <VideoPlaceholder startVideo={startVideo}></VideoPlaceholder>
        </div>
      </div>
      <Gallery className={!isGalleryActive ? "transparentComponent" : ""} lightboxIsOpen={lightboxIsOpen} />
    </div>
  );
};

export default MenuList;
