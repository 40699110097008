import React, { useState } from "react";
import ReactPlayer from "react-player";
import "./VideoPlaceholder.css";

const VideoPlaceholder = (props) => {
  const [placeholderVideo, setPlaceholderVideo] = useState(window.placeholderVideo);
  return (
    <div className="videoPlaceholderContainer">
      <ReactPlayer 
        id="videooo_placeholder"
        className={`placeholderVideo`}
        url={placeholderVideo}
        playing={true}
        volume={1}
        muted={true}
        loop={true}
        controls={false}
        width="1200px"
        height="1080px"
      ></ReactPlayer>
    </div>
  );
};

export default VideoPlaceholder;
