import React, { useState, useCallback, useEffect, useRef } from "react";
import "./Gallery.css";
import GalleryLightbox from "react-grid-gallery";

const Gallery = (props) => {

  const galleryRef = useRef(null);
  const [photosWithThumb, setPhotosWithThumb] = useState(window.photosWithThumb);
 
  useEffect(() => {
    document.body.addEventListener("touchStart", closeSidemenu);
    document.body.addEventListener("click", closeSidemenu);

    return function cleanup() {
      document.body.removeEventListener("touchStart", closeSidemenu);
      document.body.removeEventListener("click", closeSidemenu);
    };
  }, []);

  let closeSidemenu = () => {
    // console.log('screen touched')
  };



//  if(!props.lightboxIsOpen) {
//    galleryRef.current.closeLightbox();  
//  }
  
  return (
    <div className={`galleryContainer ${props.className}`}>
      <GalleryLightbox 
        ref={galleryRef} 
        images={photosWithThumb} 
        backdropClosesModal={true}  
        isOpen={false} />
    </div>
  );
};

export default Gallery;
